<template>
  <div class="p-1">
      <b-card v-if="!urlExpired">
          <div id="container-map" v-if="status_mapa">
              <l-map :center="center" :zoom="17" ref="mymap" style="height: 100%; overflow: hidden;">
                  <l-tile-layer :key="tileProvider.name" :name="tileProvider.name" :url="tileProvider.url" :visible="tileProvider.visible" layer-type="base" v-for="tileProvider in tileProviders">
                  </l-tile-layer>
                  <l-control position="topleft" v-if="showButtons === true">
                    <b-button @click="showTrail = !showTrail" class="btn-icon btn-map rounded-circle" variant="primary" :title="$t('Labels.ShowTrail')" v-b-tooltip.hover="{ variant: 'primary' }">
                      <iconBase :width="18" :height="18" iconColor="#FFFF"> <iconDistance/></iconBase>
                    </b-button>
                  </l-control>
                  <l-control position="topright">
                    <b-button v-if="!showCardInfo" @click="showCardInfo = !showCardInfo" class="btn-icon btn-map rounded-circle" variant="primary" :title="$t('Labels.TrackerInfo')" v-b-tooltip.hover="{ variant: 'secondary' }" >
                      <feather-icon icon="InfoIcon" />
                    </b-button>
                    <b-card v-if="showCardInfo">
                      <b-button
                        variant="flat-primary"
                        class="btn-icon float-right btn-map rounded-circle"
                        @click="showCardInfo = false"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                      <valueItem
                        :value="response.name"
                        :description="$t('Labels.Alias')"
                        img="pointtracker"
                      />
                      <valueItem
                        :value="response.lat"
                        :description="$t('Labels.Latitude')"
                        img="georeference"
                      />
                      <valueItem
                        :value="response.long"
                        :description="$t('Labels.Longitude')"
                        img="georeference"
                      />
                      <valueItem
                        :value="batteryCalculate(response.battery)"
                        :description="$t('Labels.Battery')"
                        img="battery"
                        unit="%"
                      />
                      <hr>
                      <valueItem
                        :value="response.updated_at.split(' ')[0]"
                        :description="response.updated_at.split(' ')[1]"
                        img="fecha"
                      ></valueItem>
                      <alertTracker :status="response.tipo_alerta" />
                    </b-card>
                  </l-control>
                  <l-marker :draggable="false" v-if="marker != null" :lat-lng="marker">
                      <l-icon :icon-size="[40,40]">
                          <avatarMarker
                          :label="response.name.substr(0,2)"
                          :status="response.tipo_alerta"
                        />
                      </l-icon>
                  </l-marker>
                  <l-layer-group :visible="showTrail">
                    <l-circle-marker v-for="(point, index) in trailPointers" :key="index"
                      :lat-lng="point.position"
                      :radius="5"
                      :color="point.status"
                    />
                    <v-polyline-decorator :paths="[polylinePointers]" :patterns="patternsTrail" />
                    <l-polyline :lat-lngs="polylinePointers" color="#00c3c3" />
                  </l-layer-group>
              </l-map>
          </div>
      </b-card>
      <b-card
        v-else
        class="d-block my-5 mx-auto w-75"
      >
        <b-img :src="require('@/assets/images/logo/logo.png')" fluid alt="Logo Spore" class="d-block mx-auto my-auto" />
        <h2 class="text-center mt-3">{{$t('Labels.URLExpired')}}</h2>
      </b-card>
  </div>
</template>
<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LCircleMarker,
  LLayerGroup,
  LPolyline,
  LControl
} from 'vue2-leaflet'
import L from 'leaflet'
import valueItem from '@core/spore-components/valueItem/valueItem.vue'
import alertTracker from '@core/spore-components/alertTracker/alertTracker.vue'
import avatarMarker from '@core/spore-components/avatarMarker/avatarMarker.vue'
import request from '@/libs/request/index'
import 'leaflet/dist/leaflet.css'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import 'leaflet-fullscreen/dist/Leaflet.fullscreen'
import { empty, batteryCalculate } from '@/libs/tools/helpers'
import {
  BCard,
  BImg,
  BButton
} from 'bootstrap-vue'
import iconBase from '@core/spore-components/icons/iconBase'
import iconDistance from '@core/spore-components/icons/iconDistance'
import Vue2LeafletPolylineDecorator from 'vue2-leaflet-polylinedecorator'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
import Vue from 'vue'
import store from '@/store'

const tileProviders = [
  {
    name: 'Gris',
    visible: true,
    url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
  },
  {
    name: 'Calles',
    visible: false,
    url: 'https://{s}.tile.openstreetmap.fr/osmfr//{z}/{x}/{y}.png'
  },
  {
    name: 'Satelite',
    visible: false,
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png'
  }
]

export default {
  name: 'mapa',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    valueItem,
    alertTracker,
    BCard,
    avatarMarker,
    BImg,
    'v-polyline-decorator': Vue2LeafletPolylineDecorator,
    LLayerGroup,
    LPolyline,
    LCircleMarker,
    LControl,
    iconDistance,
    iconBase,
    BButton
  },
  data () {
    return {
      response: [],
      tileProviders,
      center: [20.662396323989693, -103.37640969334421],
      staticAnchor: [12, 27],
      marker: null,
      status_mapa: false,
      batteryCalculate,
      uuid: this.$route.params.uuid,
      urlExpired: false,
      trailPointers: [],
      showTrail: false,
      patternsTrail: [{
        offset: 25,
        repeat: 50,
        symbol: L.Symbol.arrowHead({
          pixelSize: 15,
          pathOptions: {
            fillOpacity: 1,
            weight: 5
          }
        })
      }],
      polylinePointers: [],
      showButtons: false,
      showCardInfo: true
    }
  },
  methods: {
    async getMapa () {
      const params = {
        url: `tracker_public/${this.uuid}`,
        method: 'GET',
        validate: false
      }
      await request(params).then(data => {
        this.response = data.data.data
        if (!empty(this.response.lat) && !empty(this.response.long) && this.response.lat !== '0' && this.response.long !== '0') {
          this.marker = L.latLng(this.response.lat, this.response.long)
          this.center = L.latLng(this.response.lat, this.response.long)
          this.status_mapa = true
        }
        const app = this
        setTimeout(function () {
          const maps = app.$refs.mymap.mapObject
          maps.addControl(new L.Control.Fullscreen({
            position: 'topleft'
          }))
          app.showButtons = true
        }, 1000)
      }).catch(() => {
        this.urlExpired = true
      })
    }
  },
  sockets: {
    trackers (payload) {
      if (payload.uuid === this.response.uuid) {
        if (!empty(payload.lat) && payload.lat !== '0' && !empty(payload.long) && payload.long !== '0') {
          this.marker = L.latLng(payload.lat, payload.long)
          this.center = L.latLng(payload.lat, payload.long)
          this.trailPointers.push({
            position: [payload.lat, payload.long],
            status: payload.tipo_alerta === 'S' ? '#28c76f' : '#EA5455'
          })
          this.polylinePointers.push([payload.lat, payload.long])
          this.response.lat = payload.lat
          this.response.long = payload.long
          this.response.tipo_alerta = payload.tipo_alerta
          this.response.battery = payload.battery
          this.response.updated_at = payload.updated_at
          this.response.name = payload.name
        }
      }
    }
  },
  mounted () {
    const serverPublic = SocketIO.connect(process.env.VUE_APP_SOCKET_URL || '', {
      extraHeaders: {
        token_public: this.uuid
      },
      autoConnect: true,
      useConnectionNamespace: true
    })

    Vue.use(new VueSocketIO({
      debug: true,
      connection: serverPublic,
      vuex: {
        store
      },
      options: {
        useConnectionNamespace: true
      }
    })
    )
    this.$socket.open()
  },
  created () {
    this.getMapa()
  },
  beforeDestroy () {
    this.$socket.close()
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/pages/PublicMap.scss";
  @import "~leaflet.markercluster/dist/MarkerCluster.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
</style>
